<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                            >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>
             <v-col>
                    <v-card color="blue lighten-5">
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="regionModel" 
                                        :items="regionLists" 
                                        item-value="region" 
                                        item-text="region" 
                                        label="Region" 
                                        :disabled="disabled_region" 
                                        @change="(event) => updateOfficeDropdown(event)" 
                                        clearable 
                                        prepend-icon="mdi-home-variant"
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                         solo
                                         class="ma-0 pa-2"
                                         hide-details=true 
                                         v-model="officeModel" 
                                         :items="officeLists"      
                                         item-value="office_id" 
                                         item-text="office" 
                                         label="branch" 
                                         :disabled="disabled_office" 
                                         @change="(event) => updateSalesmanDropdown(event)" 
                                         clearable 
                                         prepend-icon="mdi-store" 
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-autocomplete 
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="salesmanModel" 
                                        :items="salesmanLists" 
                                        default="" 
                                        item-value="sales_id" 
                                        item-text="nama" 
                                        label="Salesman" 
                                        :disabled="disabled_salesman"  
                                        clearable 
                                        prepend-icon="mdi-account-circle" 
                                        >
                                    </v-autocomplete>
                                    </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-autocomplete
                                        solo
                                        class="ma-0 pa-2"
                                        hide-details=true 
                                        v-model="Customer" 
                                        :items="datas" 
                                        item-value="customerid" 
                                        item-text="namacustomer" 
                                        label="Customer Name"  
                                        clearable 
                                        prepend-icon="mdi-rowing"
                                        >
                                    </v-autocomplete>
                                </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateStartModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateStartModel"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                                <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        clearable 
                                        v-model="dateEndModel"
                                        label="Start Date"
                                        persistent-hint
                                        prepend-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-2"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateEndModel"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>   
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn class="ml-10" 
                                    color="info" elevation="2" large @click="getData()">Search</v-btn>
                                </v-col>
                                 <v-row class="mt-2" style="padding-bottom:50px">
                                    <v-col cols="12">
                                        <v-card class="rounded-l elevation-5 ml-3" color="blue lighten-5">
                                            <v-card-text>
                                                <h5>Result</h5>
                                                <v-row justify="end">
                                                  <v-col cols="12" xs="12" sm="12" md="2" class="text-right">
                                                     <v-btn class="mb-4 mr-2 text-white" 
                                                        color="#0D47A1" elevation="2" medium>
                                                        <download-excel
                                                        class="text"
                                                        :fetch           = "exportExcel"
                                                        :fields="headersColumn"
                                                        :before-generate = "startDownload"
                                                        :before-finish   = "finishDownload">
                                                        Export Excel
                                                        </download-excel> 
                                                     <v-icon>mdi-arrow-down-bold-box</v-icon>
                                                    </v-btn>
                                                </v-col>
                                                 </v-row>
                                                <v-data-table
                                                    
                                                    :headers="headers"
                                                    :items="itemLists"
                                                    class="elevation-1" 
                                                    page-count="5" 
                                                    :search="searchItem" 
                                                    :loading="loadingDatatable" 
                                                    dense
                                                    > 
                                                 <template v-slot:top>
                                                <v-toolbar flat color="white">
                                                    <div class="col-6">
                                                <v-text-field v-model="searchItem" append-icon="mdi-magnify" class="w-50" label="Search" dense single-line hide-details></v-text-field>
                                            </div>
                                                </v-toolbar>
                                            </template>
                                            <template v-slot:[`item.actions`]="{ item }">
                                                        
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showItem(item)" v-on="on">
                                                                        mdi-eye
                                                                    </v-icon>
                                                                </template>
                                                                <span>View Map</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showFileDetail(item)" v-on="on">
                                                                        mdi-file-image
                                                                    </v-icon>
                                                                </template>
                                                                <span>View Image</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="showFeedback(item)" v-on="on">
                                                                        mdi-comment-processing
                                                                    </v-icon>
                                                                </template>
                                                                <span>Feedback</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-icon small class="mr-2" @click="printItem(item)" v-on="on">
                                                                        mdi-printer
                                                                    </v-icon>
                                                                </template>
                                                                <span>Print</span>
                                                            </v-tooltip>
                                                        </td>
                                                    </template>
                                            <template v-slot:[`item.activity`]="{ item }">
                                                <v-chip v-if="item.presentasi != 'N'" class="ma-2" color="cyan darken-3" small>
                                                    <span class="text-white">PENAWARAN PRODUK</span>
                                                </v-chip>
                                                <v-chip v-if="item.takingorder != 'N'" class="ma-2" color="cyan darken-3" small>
                                                    <span class="text-white">TAKING ORDER</span>
                                                </v-chip>
                                                <v-chip v-if="item.info != 'N'" class="ma-2" color="cyan darken-3" small>
                                                    <span class="text-white">INFO PROGRAM</span>
                                                </v-chip>
                                                <v-chip v-if="item.penagihan != 'N'" class="ma-2" color="cyan darken-3" small>
                                                    <span class="text-white">PENAGIHAN</span>
                                                </v-chip>
                                                <v-chip v-if="item.maintenance != 'N'" class="ma-2" color="cyan darken-3" small>
                                                    <span class="text-white">CUSTOMER VISIT</span>
                                                </v-chip>
                                            </template>
                                            <!-- <template v-slot:[`item.showMap`]="{ item }">
                                                <td>
                                                    <v-btn class="mr-4 text-white" color="#26C6DA" elevation="1" dense small rounded @click="showItem(item)" > <v-icon >mdi-google-maps</v-icon></v-btn>
                                                </td>
                                                <td v-if="item.img_url != ''">
                                                    <v-chip class="mr-4" color="#283593" elevation="2" dense small rounded @click="showImageModal(item.img_url)" dark><v-icon dark>mdi-file-image</v-icon></v-chip>
                                                </td>
                                                <td>
                                                    <v-btn class="mr-4 text-black" v-if="item.feedback_status == '' || item.kacab_feedback == ''" color="#64FFDA" elevation="2" small @click="showFeedback(item)" ><v-icon dark>mdi-comment-processing</v-icon></v-btn>
                                                    <v-btn class="mr-4 " v-if="item.feedback_status == 'K'" color="yellow" elevation="2" small rounded @click="showFeedback(item)" ><v-icon dark>mdi-note-outline</v-icon></v-btn>
                                                    <v-btn class="mr-4" v-if="item.feedback_status == 'S'" color="green" elevation="2" small rounded @click="showFeedback(item)" ><v-icon dark>mdi-note-outline</v-icon></v-btn>
                                                </td>
                                            </template>    -->
                                                </v-data-table>
                                            </v-card-text>
                                        </v-card>
                                        <v-dialog v-model="dialog" max-width="1000px">                
                                    <v-card>
                                        <v-card-title>Detail Location</v-card-title>
                                        <v-card-text>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width: 80%;">
                                                                                        <div class="d-flex">
                                                                                            <img src="@/assets/villa.jpg" height="60px" alt="">
                                                                                            <p class="mt-4 ml-4">Griya Kusuma</p>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        <strong>Sales Activity</strong>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                                            <tbody>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Date
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                                    </td>
                                                                                    <!-- <td style="width:15%; padding-left: 5px;">
                                                                                        Activity
                                                                                    </td> -->
                                                                                    <!-- <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td  style="width:30%">
                                                                                        <div v-html="this.po_item ? this.po_item.activity : ''" style="color: black;"></div>
                                                                                    </td> -->
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Customer
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Address
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.alamat : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Remark
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4" style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.remark : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Sales ID
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td style="width:30%">
                                                                                        {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                                    </td>
                                                                                </tr>
                                                                                <!-- <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                                    <td style="width:15%; padding-left: 5px;">
                                                                                        Image
                                                                                    </td>
                                                                                    <td style="width:5%">
                                                                                        :
                                                                                    </td>
                                                                                    <td colspan="4"  style="width:30%">
                                                                                        <v-img
                                                                                        max-height="150"
                                                                                        max-width="250"
                                                                                        :src="this.po_item ? this.po_item.img_url : ''"
                                                                                        ></v-img>
                                                                                    </td>
                                                                                </tr> -->
                                                                            </tbody>
                                                                        </table>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12">
                                                        <div id="map_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                        </v-dialog>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-container>
                    </v-card>
                </v-col>
            </div>
            <v-dialog
        v-model="feedbackModal"
        persistent
        max-width="800px"
        >
        <v-card class="p-2 rounded-l">
            <v-card-title class="text-center">
                <v-row>
                    <h4
                    class="text-center"
                    style="
                    font-weight: 600;
                    color: #053d76;
                    margin-bottom: 15px;
                    margin-top: 2px;">
                   Sales Visit Feedback
                    </h4>
                <v-divider
                    style="
                    border-top: 3px solid #0078d4;
                    margin-top: 5px;"></v-divider>
                </v-row>
            </v-card-title>
             <v-card-text>
                <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                    <v-row>
                        <!-- <v-col class="col-12" sm="4" md="4">
                            <v-card class="elevation-1 rounded-5">
                            </v-card>
                        </v-col> -->
                        <v-col class="col-12" sm="8" md="12">
                        <v-container fluid>
                            <v-row>
                            <div class="col-lg-12 ">
                                 <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" clearable outlined :disabled="disabled_feedback1" ></v-textarea>
                            </div>
                            <div class="col-lg-12 ">
                                 <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" clearable outlined :disabled="disabled_feedback2"></v-textarea>
                            </div>
                            <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="4">
                                    <v-btn block class="rounded-l text-white"  color="success" @click="storeFeedback()" >Submit</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                                </v-row>
                        </v-container>
                        </v-col>
                        </v-row>
                        </v-container>
                    </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l text-white"  color="red" @click.stop="feedbackModal=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogPrint"
            persistent
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            style="border-radius: 0 !important"
            >
            <v-card class="p-2 rounded-l">
                <v-card-title class="text-center">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex">
                                Print Sales Activity
                                <v-spacer></v-spacer>
                                <v-btn class="rounded-l text-white" color="success" @click="printTicket()">
                                    <v-icon small class="mr-2">
                                        mdi-printer
                                    </v-icon>
                                    Print
                                </v-btn>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialogPrint = false]"
                                >
                                    <v-icon dark>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row align="center" justify="center" class="mt-4">
                        <div style="width: 960px;" id="divPrintSalesActivityKbt">
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12">
                                        <table cellspacing="0" cellpadding="0" class="container" style="width: 100%;; margin-top: 20px; padding: 20px;border: 1px solid #DDD;font-size: 14px;">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; padding-bottom: 10px;border-bottom: 1px solid #DDD;">
                                                            <tbody>
                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                    <td style="width: 80%;">
                                                                        <div class="d-flex">
                                                                            <img src="@/assets/villa.jpg" height="60px" alt="">
                                                                            <p class="mt-4 ml-4">GRIYA KUSUMA</p>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <strong>Sales Activity</strong>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table width="100%" cellspacing="0" cellpadding="0" style="width: 100%; margin: 10px 0;">
                                                            <tbody>
                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                    <td style="width:15%; padding-left: 5px;">
                                                                        Date
                                                                    </td>
                                                                    <td style="width:5%">
                                                                        :
                                                                    </td>
                                                                    <td style="width:30%">
                                                                        {{ this.po_item ? this.po_item.tr_date : '' }}
                                                                    </td>
                                                                    <!-- <td style="width:15%; padding-left: 5px;">
                                                                        Activity
                                                                    </td> -->
                                                                    <!-- <td style="width:5%">
                                                                        :
                                                                    </td>
                                                                    <td  style="width:30%">
                                                                        <div v-html="this.po_item.activity ? this.po_item.activity : ''" style="color: black;"></div>
                                                                    </td> -->
                                                                </tr>
                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                    <td style="width:15%; padding-left: 5px;">
                                                                        Customer
                                                                    </td>
                                                                    <td style="width:5%">
                                                                        :
                                                                    </td>
                                                                    <td style="width:30%">
                                                                        {{ this.po_item ? this.po_item.namacustomer : '' }}
                                                                    </td>
                                                                </tr>
                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                    <td style="width:15%; padding-left: 5px;">
                                                                        Address
                                                                    </td>
                                                                    <td style="width:5%">
                                                                        :
                                                                    </td>
                                                                    <td colspan="4"  style="width:30%">
                                                                        {{ this.po_item ? this.po_item.alamat : '' }}
                                                                    </td>
                                                                </tr>
                                                                <tr style="margin-top: 8px; margin-bottom: 8px;">
                                                                    <td style="width:15%; padding-left: 5px;">
                                                                        Remark
                                                                    </td>
                                                                    <td style="width:5%">
                                                                        :
                                                                    </td>
                                                                    <td colspan="4" style="width:30%">
                                                                        {{ this.po_item ? this.po_item.remark : '' }}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                <td style="width:15%; padding-left: 5px;">
                                                                    Sales ID
                                                                </td>
                                                                <td style="width:5%">
                                                                    :
                                                                </td>
                                                                <td style="width:30%">
                                                                    {{ this.po_item ? this.po_item.salesid : '' }} - {{ this.po_item ? this.po_item.nama : '' }}
                                                                </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-5">
                                    <v-col cols="12">
                                        <div id="map_print_canvas" style="height:432px; width:100%; border: 1px solid grey;"></div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </div>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- <v-dialog
        v-model="dialog"
        persistent
        max-width="800px"
        >
        <v-card class="p-2 rounded-l">
            <v-card-title class="text-center">
                <v-row>
                    <h4
                    class="text-center"
                    style="
                    font-weight: 600;
                    color: #053d76;
                    margin-bottom: 15px;
                    margin-top: 2px;">
                    Detail Map Location
                    </h4>
                <v-divider
                    style="
                    border-top: 3px solid #0078d4;
                    margin-top: 5px;"></v-divider>
                </v-row>
            </v-card-title>
             <v-card-text>
                <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                    <v-row>
                        <v-col class="col-12" sm="4" md="12">
                            <v-card class="elevation-1 rounded-2">      
                                        <div id="map_canvas" style="height:432px; width:100%"></div>
                            </v-card>
                        </v-col>             
                        </v-row>
                        </v-container>
                    </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                      <v-btn dense block color="info" text @click="close">Cancel</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                    
        </v-dialog> -->
        <!-- <div class="modal fade modal-xl" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                    <div class="modal-header">
                        <h4 class="modal-title">Sales Visit Feedback </h4>
                    </div>
                    <div class="modal-body">
                        <div class="col-lg-12">
                            <v-textarea v-model="kacabFeedbackModel" label="KACAB FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback1" ></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-textarea v-model="salesFeedbackModel" label="SALES FEEDBACK" dense prepend-icon="mdi-note-outline" :disabled="disabled_feedback2"></v-textarea>
                        </div>
                        <div class="col-lg-12">
                            <v-btn class="" color="success" elevation="2" block rounded @click="storeFeedback()" >SUBMIT</v-btn>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div> -->
        <v-dialog
        v-model="dialog_file_doc"
        persistent
        max-width="1300px"
        >
        <v-card class="p-2 rounded-l">
            <v-card-title class="text-center">
                <v-row>
                    <h4
                    class="text-center"
                    style="
                    font-weight: 600;
                    color: #053d76;
                    margin-bottom: 15px;
                    margin-top: 2px;">
                    Report Sales
                    </h4>
                <v-divider
                    style="
                    border-top: 3px solid #0078d4;
                    margin-top: 5px;"></v-divider>
                </v-row>
            </v-card-title>
             <v-card-text>
                <v-container fluid class="pl-5 pr-5 pt-5 pb-4">
                    <v-row>
                        <v-col class="col-12" sm="4" md="4">
                            <v-card class="elevation-1 rounded-5">
                                <v-card-text class="mt-15">
                                    <!-- <td v-if="item.img_url != ''"> -->
                                    <v-img :src=imageUrl ></v-img>
                                     <!-- </td> -->
                                    <!-- <v-img v-for="file in doc_files" :key="file.file_name" :src="file.file_name"></v-img> -->
                                    <p class="css-bqy72x mt-5">Besar file: maksimum (1 Megabytes). Ekstensi file yang diperbolehkan: .JPG .JPEG .PNG</p>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col class="col-12" sm="8" md="8">
                        <v-container fluid>
                            <v-col cols="12">
                                <h5 class="text-center" 
                                style="
                                font-weight: 600;
                                color: #053d76;
                                margin-bottom: 45px;
                                margin-top: 2px;"><span>Report Sales Info</span></h5>
                            </v-col>
                            <v-row>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Sales ID<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable
                                    v-model="salesid" 
                                    disabled 
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Customer Name<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable
                                    v-model="namacustomer"
                                    disabled 
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Address<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable
                                    v-model="alamat"
                                    disabled
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">City<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable
                                    v-model="city"
                                    disabled
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Date<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable 
                                    v-model="tr_date"
                                    disabled
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="4">
                                <h6 class="blue-lcd mb-1">Tr ID<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable 
                                    v-model="tr_id"
                                    disabled
                                ></v-text-field> 
                            </v-col>
                            <v-col class="col-12" sm="3" md="10">
                                <h6 class="blue-lcd mb-1">Lt Location<span class="red--text"></span></h6>
                                <v-text-field
                                    dense
                                    solo
                                    clearable 
                                    v-model="lt_location"
                                    disabled
                                ></v-text-field> 
                            </v-col>
                            <div class="col-lg-12 ">
                                 <v-textarea v-model="remark" label="REMARK" clearable outlined></v-textarea>
                            </div>
                               <v-row class="mt-12">
                                            <v-col cols="12">
                                                <v-alert
                                                icon="mdi-shield-lock-outline"
                                                prominent
                                                text
                                                color="red"
                                                >
                                                Cek kembali data setiap user pastikan data benar dan tidak ada kesalahan input data user
                                                </v-alert>
                                            </v-col>
                                        </v-row>
                                </v-row>
                        </v-container>
                        </v-col>
                        </v-row>
                        </v-container>
                    </v-card-text>
                        <v-card-actions class="text-center">
                            <v-row justify="center">
                                <v-col cols="12" xs="12" sm="3" md="2">
                                    <v-btn block class="rounded-l text-white"  color="red" @click.stop="dialog_file_doc=false">Close</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
        </v-dialog>
        <!-- <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <v-img :src=imageUrl ></v-img>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div> -->
        </div>
</template>

<script>

export default {
    data() {
        return {
            loading: false,
                snackbar: {
                    color: null,
                    icon: null,
                    mode: null,
                    position: "top",
                    text: null,
                    timeout: 7500,
                    title: null,
                    visible: false
                },
            breadcumbs: [
                {
                text: 'Sales Activity',
                disabled: false,
                href: '#',
                },
                {
                text: 'Visit Report',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            
            regionLists: [],
            officeModel: '',
            officeLists: [],
            dialog: false,
            loading3: false,
            salesmanModel: '',
            salesmanLists: [],
            dateStartModel: '',
            dateEndModel: '',
            headers: [
                { text: 'Salesman', value: 'salesid', align: 'left' },
                { text: 'Customer ID', value: 'customerid', align: 'left' },
                { text: 'Customer Name', value: 'namacustomer', align: 'left' },
                { text: 'Address', value: 'alamat', align: 'left' },
                { text: 'City', value: 'city', align: 'left'},
                { text: 'Date', value: 'tr_date', align: 'left'},
                { text: 'GPS Address', value: 'lt_location', align: 'left'},
                { text: 'Activity', value: 'activity', align: 'center'},
                { text: 'Remark', value: 'remark', align: 'left'},
                { text: '', value: 'showMap', align: 'center'},
                { text: 'Actions',  value: 'actions', width:'100', sortable: false }
            ],
            headersColumn: {
            'Office ID':'office_id',
            'Seles id': 'salesid',
            'Customer ID': 'customerid',
            'Nama Customer' : 'namacustomer',
            'Alamat': 'alamat',
            'City': 'city',
            'Date': 'tr_date',
            'Location': 'lt_location',
            'Note': 'remark',
            'Sales Latitude': 'sales_latitude',
            'Sales Lonitude': 'sales_longitude',
            'Feedback': 'feedback_status',
            },
            itemLists: [],
            datas:[],
            searchItem: '',
            loadingDatatable: false,
            disabled_region: false,
            dialogLocation:false,
            disabled_office: false,
            disabled_salesman: false,
            dateStartModal: false,
            dateEndModal: false,
            mymap: '',
            kacabFeedbackModel: '',
            po_items: [],
            po_item: '',
            salesid:'',
            namacustomer:'',
            alamat:'',
            tr_date:'',
            tr_id:'',
            remark:'',
            city:'',
            lt_location:'',
            tr_id:'',
            salesFeedbackModel: '',
            disabled_feedback1: true,
            disabled_feedback2: true,
            dialog_file_doc: false,
            feedbackModal:false,
            userGroup: '',
            tr_id: '',
            tr_date:'',
            dialogPrint: false,
            userGroup: null,
            appl_id: 'SALES ACTIVITY',
            modal: false,
            modal_to: false,
            imageUrl: '',
            Customer:'',
            search: null,
            lt_location:'',
            img_url:'',
        }
    },
    async mounted(){
        
        this.initialize()
        this.GetCustomer()

    },
    methods:{
        
        async initialize(){
            // this.$store.dispatch('setOverlay', true)
            // document.querySelector(".cardResult").style.display = "none";

            this.dateStartModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            this.dateEndModel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            await axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                console.log(res.data)
                this.userGroup = res.data.group
                if(res.data.region.length == 1){
                    this.regionModel = res.data.region[0].region
                    this.regionLists = res.data.region
                    this.disabled_region = true
                } else {
                    this.regionLists = res.data.region
                    this.disabled_region = false
                }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].sales_id
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }
                this.$store.dispatch('setOverlay', false)
            })
        },
        close() {
            this.dialog = false
        },
        printTicket(){
            const elem = document.getElementById("divPrintSalesActivityKbt")
            var domClone = elem.cloneNode(true);
    
            var $printSectionSalesActivityKbt = document.getElementById("printSectionSalesActivityKbt");
            
            if (!$printSectionSalesActivityKbt) {
                var $printSectionSalesActivityKbt = document.createElement("div");
                $printSectionSalesActivityKbt.id = "printSectionSalesActivityKbt";
                document.body.appendChild($printSectionSalesActivityKbt);
            }
            
            $printSectionSalesActivityKbt.innerHTML = "";
            $printSectionSalesActivityKbt.appendChild(domClone);
            window.print();
        }, 
        startDownload(){
           this.loading = true
           this.disabledExport=true
        },
        finishDownload(){
            this.loading = false
            this.disabledExport=false
        },
        async GetCustomer(){
            await axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getCustomer`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
               // console.log(res.data)
                this.datas = res.data
            })
            
        },
        async getData(){

          //  document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }
            await axios.post(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getData`, { 
                    region: this.regionModel ? this.regionModel : '',
                    namacustomer:this.Customer ? this.Customer :'',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                   // document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })

        },
      async exportExcel() {
        if (this.dateStartModel === '') {
                this.snackbar = {
                  color: "error",
                  icon: "mdi-alert-circle",
                  mode: "multi-line",
                  position: "top",
                  timeout: 7500,
                  title: "Error",
                  text: "Please fill period",
                  visible: true
                };
             return false;
        } else {
            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }
            const response = await axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/GetExcel?search=${this.$store.state.text}&office=${this.officeModel ? this.officeModel : ''}&region=${this.regionModel ? this.regionModel : ''}&sales=${this.salesmanModel ? this.salesmanModel : ''}&dateFrom=${this.dateStartFormatted ? this.dateStartFormatted : ''}&dateTo=${this.dateEndFormatted ? this.dateEndFormatted : ''}`, {
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` }
            });
            console.log(response.data);
            return response.data.result
            
        }

        },
        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })

            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getSalesmanById?officeid=${id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
            }
        },
        async printItem(item){
            this.po_item = item
            this.dialogPrint = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_print_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        async showItem(item){
            console.log(item)
            this.po_item = item
            this.dialog = true
            this.dialogLocation = true
            this.loading = true

            if (this.mymap != '') {
                this.mymap.off();
                this.mymap.remove();
            }

            await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${this.po_item.sales_latitude}&lon=${this.po_item.sales_longitude}`)
            .then(res => {
                this.loading = false
                var address = res.data.address.village +', '+ res.data.address.state_district +', '+ res.data.address.state;

                this.mymap = L.map('map_canvas').setView([this.po_item.sales_latitude, this.po_item.sales_longitude], 13);
        
                L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
                    maxZoom: 18,
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                    tileSize: 512,
                    zoomOffset: -1
                }).addTo(this.mymap);

                L.marker([this.po_item.sales_latitude, this.po_item.sales_longitude],
                    {draggable: true,        // Make the icon dragable
                    //title: 'Hover Text',     // Add a title
                    opacity: 0.5}            // Adjust the opacity
                    )
                    .addTo(this.mymap)
                    .bindPopup('<b>Your Sales Visit location</b><br>'+address)
                    .openPopup();

            });
        },
        showItemMap(item){
            if (this.mymap != '') {
                this.mymap.off()
                this.mymap.remove()
            }
            $('#detailModal').modal('show')

            this.mymap = L.map('mapCanvas').setView([item.sales_latitude, item.sales_longitude], 15);
        
            L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${process.env.TK_SRS}`, {
            // L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
                        '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
                        'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
            }).addTo(this.mymap)

            L.marker([item.sales_latitude, item.sales_longitude],
                {draggable: false,
                //title: 'Hover Text',
                opacity: 1}
                )
                .addTo(this.mymap)
                .bindPopup('<b>Your GPS location</b><br> ')
                .openPopup()

        },

        async showFeedback(item){

            await axios.get(`${process.env.VUE_APP_URL}/api/griya/VisitReport/getFeedback?id=${item.tr_id}`, { 
                    headers: { 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
            })
            .then(res => {
                this.feedbackModal = true
                //$('#feedbackModal').modal('show')
                this.tr_id = item.tr_id
                this.kacabFeedbackModel = res.data.result[0].kacab_feedback
                this.salesFeedbackModel = res.data.result[0].sales_feedback
                if(this.userGroup == 'SALES KMB'){
                    this.disabled_feedback2 = false
                } else {
                    this.disabled_feedback1 = false
                }
            })

        },

        async storeFeedback(){
           this.$store.dispatch('setOverlay', true)

            await axios.post(`${process.env.VUE_APP_URL}/api/griya/VisitReport/storeFeedback`, { 
                    group: this.userGroup ? this.userGroup : '',
                    tr_id: this.tr_id ? this.tr_id : '',
                    kacabFeedback: this.kacabFeedbackModel ? this.kacabFeedbackModel : '',
                    salesFeedback: this.salesFeedback ? this.salesFeedback : ''
                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                Swal.fire({
                    text: 'Feedback submitted succesfully',
                    icon: 'success'
                })
                this.getData()
                this.feedbackModal = true
                // $('#feedbackModal').modal('hide')
                this.$store.dispatch('setOverlay', false)
            })

        },

        currentDate() {
            const current = new Date();
            // const date = `$current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },
        showFileDetail(item){
          console.log(item);
          this.namacustomer = item.namacustomer
          this.salesid = item.salesid
          this.remark = item.remark
          this.alamat = item.alamat
          this.city = item.city
          this.tr_id = item.tr_id
          this.tr_date = item.tr_date
          this.lt_location = item.lt_location
          this.item = item.img_url
          this.dialog_file_doc = true
          this.showImageModal(item.img_url)
        //this.getIndex()
        },
        showImageModal(img_url){
            // $('#imgModal').modal('show')
            
            this.dialog_file_doc = true
            this.imageUrl = process.env.VUE_APP_URL + img_url

        },

    }
    
}
</script>
<style>
@media screen {
  #printSectionSalesActivityKbt {
      display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
  }
  #printSectionSalesActivityKbt, #printSectionSalesActivityKbt * {
    visibility:visible;
  }
  #printSectionSalesActivityKbt {
    position:absolute;
    left:0;
    top:0;
  }
}
 

</style>